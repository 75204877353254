<template>
  <div>
    <div class="slk-loader" v-if="isPending">
      <b-spinner label="Loading..." />
    </div>
    <!-- <backlog-nav
      @search="search"
      @filterAssignee="filterAssignee"
      :config="projectConfig"
      v-if="!activeEpic"
    ></backlog-nav>
     
   -->

    <div class="ys-backlog d-flex mt-2">
      <!--
      <backlog-tab v-if="isShowTab" @close="closeTabs" :tabIndex="tabIndex"></backlog-tab>
      
      <epic-tasks v-if="activeEpic"></epic-tasks>
      -->
      <div class="w-100">
        <div class="ys-cards__wrap">
          <div class="ys-cards">
            <b-card>
              <b-card-header>
                <h6>Release</h6>
              </b-card-header>
              <b-card-body>
                <b-form>
                  <b-form-group label="Номер релиза">
                    <b-form-input placeholder="0.0.0" v-model="release.version" />
                  </b-form-group>

                  <b-form-group label="Начало работ">
                    <flat-pickr
                      v-model="release.started_at"
                      class="form-control"
                      :config="config"
                    />
                  </b-form-group>

                  <span>Внутренний deadline задач:</span>
                  <div class="pl-1 mt-1 mb-2">
                    <b-form-group label="Запланированная дата">
                      <flat-pickr
                        v-model="release.deadline.planned_at"
                        class="form-control"
                        :config="config"
                      />
                    </b-form-group>
                    <b-form-group label="Фактическая дата">
                      <flat-pickr
                        v-model="release.deadline.finished_at"
                        class="form-control"
                        :config="config"
                      />
                    </b-form-group>
                  </div>
                  <span>Тестирование готового функционала:</span>
                  <div class="pl-1 mt-1 mb-2">
                    <b-form-group label="Запланированная дата">
                      <flat-pickr
                        v-model="release.qa.planned_at"
                        class="form-control"
                        :config="config"
                      />
                    </b-form-group>
                    <b-form-group label="Фактическая дата">
                      <flat-pickr
                        v-model="release.qa.finished_at"
                        class="form-control"
                        :config="config"
                      />
                    </b-form-group>
                  </div>
                  <span>Готовность RC</span>
                  <div class="pl-1 mt-1 mb-2">
                    <b-form-group label="Запланированная дата">
                      <flat-pickr
                        v-model="release.rc.planned_at"
                        class="form-control"
                        :config="config"
                      />
                    </b-form-group>
                    <b-form-group label="Фактическая дата">
                      <flat-pickr
                        v-model="release.rc.finished_at"
                        class="form-control"
                        :config="config"
                      />
                    </b-form-group>
                  </div>
                  <span>Release</span>
                  <div class="pl-1 mt-1 mb-2">
                    <b-form-group label="Запланированная дата">
                      <flat-pickr
                        v-model="release.deploy.planned_at"
                        class="form-control"
                        :config="config"
                      />
                    </b-form-group>
                    <b-form-group label="Фактическая дата">
                      <flat-pickr
                        v-model="release.deploy.finished_at"
                        class="form-control"
                        :config="config"
                      />
                    </b-form-group>
                  </div>
                </b-form>

                <b-form-group label="Статусы:">
                  <v-select
                    v-model="release.status"
                    :options="allStatuses"
                    placeholder="Выберите статус"
                    label="status"
                  />

                  <!--
              <small class="text-danger" v-if="errors[0]">Поле обязательно для заполнения</small>  -->
                </b-form-group>
                <b-form-group label="Эпики:">
                  <v-select
                    v-model="release.epics"
                    :options="findEpics"
                    placeholder="Выберите Эпики"
                    multiple
                    :filterable="false"
                    @search="(query) => onSearch(query, 'epic')"
                  >
                    <template #selected-option="{ name }">
                      <div class="vSelect-studio-img">
                        <span> {{ name }} </span>
                      </div>
                    </template>
                    <li slot="list-footer" class="pagination">
                      <button :disabled="!hasPrevPageEpic" @click="getTasks(--epicPage, 'epic')">
                        Prev
                      </button>
                      <button :disabled="!hasNextPageEpic" @click="getTasks(++epicPage, 'epic')">
                        Next
                      </button>
                    </li>

                    <template #option="{ name }">
                      <span>{{ name }}</span>
                    </template>
                  </v-select>
                </b-form-group>
                <b-form-group label="Задачи:">
                  <v-select
                    v-model="release.tasks"
                    :options="findTasks"
                    placeholder="Выберите Задачи"
                    multiple
                    :filterable="false"
                    @search="(query) => onSearch(query, 'task')"
                  >
                    <template #selected-option="{ name, key, labels }">
                      <div class="vSelect-studio-img">
                        <span>
                          {{ name }} ({{ labels.length ? labels[0].name : 'не задано' }})</span
                        >
                      </div>
                    </template>
                    <li slot="list-footer" class="pagination">
                      <button :disabled="!hasPrevPageTask" @click="getTasks(--taskPage, 'task')">
                        Prev
                      </button>
                      <button :disabled="!hasNextPageTask" @click="getTasks(++taskPage, 'task')">
                        Next
                      </button>
                    </li>
                    <template #option="{ name, key }">
                      <span>{{ name }}</span>
                    </template>
                  </v-select>
                </b-form-group>
                <div class="mt-1 mb-2">
                  <div class="mb-1">
                    <span>Документация для РО</span>
                  </div>
                  <quill-text-editor v-model="release.po"></quill-text-editor>
                </div>
                <div class="mt-1 mb-2">
                  <div class="mb-1">
                    <span>Документация для отчета</span>
                  </div>
                  <quill-text-editor v-model="release.report"></quill-text-editor>
                </div>
                <template>
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="formSubmitBt"
                    :disabled="submitDisable"
                  >
                    Сохранить
                  </button>
                </template>
              </b-card-body>
            </b-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BForm,
  BInputGroup,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BSpinner,
  BModal,
  BAlert,
  VBTooltip,
} from 'bootstrap-vue';
import moment from 'moment';
import flatPickr from 'vue-flatpickr-component';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { getErrors } from '@/helpers/errors';
import axios from 'axios';
import vSelect from 'vue-select';
import { mapActions, mapState, mapGetters } from 'vuex';
import { docsApi, taskApi } from '@/api';
import { Russian } from 'flatpickr/dist/l10n/ru';
import QuillTextEditor from '../components/QuillTextEditor';

export default {
  name: 'ReleaseForm',
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BForm,
    BInputGroup,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    flatPickr,
    vSelect,
    BSpinner,
    BModal,
    BAlert,
    ValidationObserver,
    ValidationProvider,
    QuillTextEditor,
  },

  data: () => ({
    filterTasks: null,
    filterSprints: null,
    projectConfig: null,

    release: {
      id: null,
      version: null,
      started_at: null,
      deadline: {
        planned_at: null,
        finished_at: null,
      },
      qa: {
        planned_at: null,
        finished_at: null,
      },
      rc: {
        planned_at: null,
        finished_at: null,
      },
      deploy: {
        planned_at: null,
        finished_at: null,
      },
      epics: [],
      tasks: [],
      po: '',
      report: '',
      status: '',
    },

    limit: 8,
    search: '',
    epicsTotal: 0,
    epicPage: 1,

    tasksTotal: 0,
    taskPage: 0,

    allStatuses: [],
    findEpics: [],
    findTasks: [],

    editRelease: false,
    isInitialLoad: true,
    delayTimer: null,
    delayTimerSearch: null,
    tabIndex: 1,
    releases: [],
    submitDisable: false,

    config: {
      wrap: true,
      dateFormat: 'd.m.Y H:i',
      // altInput: true,
      // altFormat: 'Y-m-d H:i', // Время, отображаемое при выборе
      enableTime: true, // Выбираем час и минуту
      defaultHour: 8, // По умолчанию 8 баллов
      time_24hr: true, // Время 24 часа
      locale: Russian, // Китайский язык
      onClose: (date, dateStr, instance) => {
        // console.log('close picker!', dateStr, instance);
      },
    },
  }),
  computed: {
    ...mapState('project', ['isPending', 'currentProject']),
    ...mapGetters('project', ['sortSprints', 'getReleaseById']),

    hasNextPageEpic() {
      const totalOnPages = this.epicPage * this.limit;
      return totalOnPages < this.epicsTotal;
    },
    hasPrevPageEpic() {
      return this.epicPage > 1;
    },
    hasNextPageTask() {
      const totalOnPages = this.taskPage * this.limit;
      return totalOnPages < this.tasksTotal;
    },
    hasPrevPageTask() {
      return this.taskPage > 1;
    },
  },
  watch: {
    'release.po'() {
      this.debounce(5000);
    },
    'release.report'() {
      this.debounce(5000);
    },
    'release.version'() {
      this.debounce();
    },
    'release.started_at'() {
      this.debounce();
    },
    'release.status'() {
      this.debounce();
    },
    'release.deadline': {
      handler() {
        this.debounce();
      },
      deep: true,
    },
    'release.qa': {
      handler() {
        this.debounce();
      },
      deep: true,
    },
    'release.rc': {
      handler() {
        this.debounce();
      },
      deep: true,
    },
    'release.deploy': {
      handler() {
        this.debounce();
      },
      deep: true,
    },
    'release.epics': {
      handler() {
        this.debounce();
      },
      deep: true,
    },
    'release.tasks': {
      handler() {
        this.debounce();
      },
      deep: true,
    },
  },
  mounted() {
    this.loadData();
    // console.log('params:', this.$route.params);
    this.getInitData();
  },
  methods: {
    ...mapActions('project', ['updatePending']),

    debounce(delay = 3000) {
      if (this.isInitialLoad) {
        console.log('isInit!');
        return;
      }
      clearTimeout(this.delayTimer);
      this.delayTimer = setTimeout(() => {
        console.log('formSubmit!');
        this.formSubmit();
      }, delay);
    },
    onSearch(query, type) {
      clearTimeout(this.delayTimerSearch);
      this.delayTimerSearch = setTimeout(() => {
        console.log('Search!');
        taskApi
          .getTasks(this.currentProject, {
            types: type == 'epic' ? ['epic'] : ['task', 'bug', 'history'],
            search: query.toLocaleLowerCase(),
          })
          .then((res) => {
            const { data, headers } = res;
            if (type == 'epic') {
              this.epicsTotal = headers['x-pagination-total'];
              this.findEpics = data.tasks;
              this.epicPage = 1;
            }
            if (type == 'task') {
              this.tasksTotal = headers['x-pagination-total'];
              this.findTasks = data.tasks;
              this.taskPage = 1;
            }
          });
        this.search = query;
      }, 3000);
    },
    getTasks(page, type) {
      taskApi
        .getTasks(this.currentProject, {
          types: type == 'epic' ? ['epic'] : ['task', 'bug', 'history'],
          ...(this.search && { search: this.search.toLocaleLowerCase() }),
          page,
        })
        .then((res) => {
          const { data, headers } = res;
          if (type == 'epic') {
            this.epicsTotal = headers['x-pagination-total'];
            this.findEpics = data.tasks;
          }
          if (type == 'task') {
            this.tasksTotal = headers['x-pagination-total'];
            this.findTasks = data.tasks;
          }
        });
    },
    getItemRelease(nameProp) {
      const val = this.release[nameProp];
      if (!!val.planned_at || !!val.finished_at) {
        const obj = {};
        obj[nameProp] = {
          ...(val.planned_at && { planned_at: val.planned_at }),
          ...(val.finished_at && { finished_at: val.finished_at }),
        };
        return obj;
      }
      return null;
    },
    formSubmitBt() {
      this.formSubmit().then(() => {
        this.$router.back();
      });
    },
    formSubmit() {
      this.submitDisable = true;
      const {
        id,
        version,
        started_at,
        deadline,
        qa,
        rc,
        deploy,
        status,
        epics,
        tasks,
        po,
        report,
      } = this.release;
      const release = {
        ...(id && { id }),
        ...(version && { version }),
        ...(started_at && { started_at }),
        ...this.getItemRelease('deadline'),
        ...this.getItemRelease('qa'),
        ...this.getItemRelease('rc'),
        ...this.getItemRelease('deploy'),
        ...(status && { status }),
        ...(epics?.length && { epics: epics.map(({ key }) => key) }),
        ...(tasks?.length && { tasks: tasks.map(({ key }) => key) }),
        ...(po && po !== '<p></p>' && { po }),
        ...(report && report !== '<p></p>' && { report }),
      };

      console.log('release:', release);
      const request = this.release.id
        ? docsApi.editRelease(release, this.currentProject)
        : docsApi.addRelease(release, this.currentProject);

      return request
        .then((res) => {
          // this.successToast();
          // console.log('res:', res);

          if (res?.data?.doc?.id && !this.release.id) {
            this.release.id = res.data.doc.id;
            this.$router.replace({ name: 'release', params: { release: res.data.doc.id } });
          }
          this.successToast();
        })
        .catch((error) => {
          console.log('error:', error.response, error.message);
          if (error.response) {
            const errors = getErrors(error.response.data.errors);
            console.log('errors:', errors);

            if (errors.general) {
              this.errorToast(errors);
            }
          }
        })
        .then(() => {
          this.submitDisable = false;
        });
      // this.submitDisable = false;
    },

    successToast() {
      this.$bvToast.toast('Релиз успешно создан/изменен', {
        title: this.$t('Сreating a release'),
        variant: 'success',
        solid: true,
      });
    },

    errorToast(errors) {
      this.$bvToast.toast(
        `Ошибка при создании задачи. ERROR: ${errors.general[0].status} ${errors.general[0].message}`,
        {
          title: this.$t('Сreating a sprint'),
          variant: 'danger',
          solid: true,
        },
      );
    },

    loadData() {
      this.$store.dispatch('project/updatePending', true);
      return axios
        .all([
          docsApi.getSchema(this.currentProject),
          taskApi.getTasks(this.currentProject, { types: ['task', 'bug', 'history'] }),
          taskApi.getTasks(this.currentProject, { types: ['epic'] }),
        ])

        .then(([resDoc, resTask, resEpic]) => {
          // console.log('schema:', res.data.doc_schema[14].dictionary);
          this.allStatuses = resDoc.data?.doc_schema[14]?.dictionary;

          this.epicsTotal = resEpic.headers['x-pagination-total'];

          this.findEpics = resEpic.data.tasks;

          this.tasksTotal = resTask.headers['x-pagination-total'];

          this.findTasks = resTask.data.tasks;
        })
        .catch(() => {
          this.$bvToast.toast(`Ошибка при загрузке данных.`, {
            title: this.$t('Data Update'),
            variant: 'danger',
            solid: true,
          });
          this.$store.dispatch('project/updatePending', false);
        })
        .then(() => this.$store.dispatch('project/updatePending', false));
    },
    setRelease(release) {
      this.release = {
        ...this.release,
        ...release,
        ...(release?.status && { status: release?.status.name }),
      };
      this.editRelease = true;
      setTimeout(() => (this.isInitialLoad = false), 100);
    },
    getInitData() {
      // ---взять из списка данные релиза в режиме редактирования---
      const {
        params: { release: id },
      } = this.$route;

      if (id !== 'new') {
        console.log('id:', id);
        const release = this.getReleaseById(id);

        // ---
        if (!release) {
          this.$store.dispatch('project/updatePending', true);
          docsApi
            .getRelease(id)
            .then((res) => {
              // console.log('res', res);
              const {
                data: {
                  doc: { properties },
                },
              } = res;
              const newRelease = {
                ...properties,
                ...(properties.epics && {
                  epics: properties.epics.map((epic) => ({
                    key: epic.task_key,
                    name: epic.task_name,
                  })),
                }),
                ...(properties.tasks && {
                  tasks: properties.tasks.map((task) => ({
                    key: task.task_key,
                    name: task.task_name,
                    //TODO
                    labels: task?.label_name ? [{ name: task.label_name }] : [],
                    ...(task.epic_name && { epic: { name: task.epic_name, key: task.epic_key } }),
                  })),
                }),
              };
              this.setRelease(newRelease);
            })
            .catch(() => {
              this.$bvToast.toast(`Ошибка при загрузке данных.`, {
                title: this.$t('Data Update'),
                variant: 'danger',
                solid: true,
              });
              this.$store.dispatch('project/updatePending', false);
            })
            .then(() => this.$store.dispatch('project/updatePending', false));
        } else {
          console.log('release:', release);
          this.setRelease(release);
        }
      } else {
        setTimeout(() => (this.isInitialLoad = false), 100);
      }
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}
.pagination button {
  /*flex: 5rem;*/
  width: 20%;
}
.pagination button:hover {
  cursor: pointer;
}
</style>
